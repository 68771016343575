/* Admin Panel styles */
.admin-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
}

.admin-container h1, .admin-container h2 {
  text-align: center;
}

.add-user-container {
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
}

.add-user-container > div {
  flex: 1;
  margin-right: 10px;
}

.add-user-container > div:last-child {
  margin-right: 0;
}

.add-user-container input, .add-user-container select, .add-user-container button {
  border: 1px solid #ccc;
  border-radius: 50px;
  box-sizing: border-box;
  height: 50%;
  margin-top: 15px;
  padding: 10px;
  width: 20%;
}

.add-user-container button {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.add-user-container button:hover {
  background-color: #45a049;
}

.user-actions {
  align-items: center;
  display: flex;
}

.user-actions button {
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 10px;
}

.user-actions .cancel-button {
  background-color: #6c757d;
}

.user-actions .delete-button {
  background-color: #ff4d4d;
}

.user-actions .delete-button:hover {
  background-color: #ff1a1a;
}

.user-actions .edit-button {
  background-color: #007BFF;
}

.user-actions .edit-button:hover {
  background-color: #0056b3;
}

.user-details {
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  font-weight: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
}

.user-details > div {
  flex: 1;
  margin: 0 10px;
}

.user-details input, .user-details select {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
  width: 100%;
}

.user-list {
  list-style-type: none;
  padding: 0;
}

.user-list-header {
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
}

.user-list-header > div, .user-details > div {
  flex: 1;
  text-align: center;
}

.user-list li {
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
}

/* Auth styles */
.auth-button {
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  font-size: 2em;
  justify-content: center;
  margin: 5px 20px;
  padding: 10px 15px;
  transition: transform 0.3s ease, background-color 0.3s;
  width: 12%;
}

.auth-button:hover {
  background-color: #ffce5d;
  border-color: #c58a00;
  transform: scale(1.1);
}

.auth-container {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
}

.auth-container a, .auth-container button {
  border: 1px solid transparent;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-size: 2em;
  padding: 10px 15px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s;
}

.auth-container a:hover, .auth-container button:hover {
  background-color: #ffa67d;
  border-color: #ff854d;
  transform: scale(1.1);
}

.auth-container .signout-button {
  background-color: #ffbfa1;
  border: 1px solid transparent;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  padding: 10px 15px;
  transition: transform 0.3s ease, background-color 0.3s;
}

.auth-container .signout-button:hover {
  background-color: #ffa67d;
  border-color: #ff9564;
  transform: scale(1.1);
}

/* Body styles */
body {
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Button styles */
.submit-button {
  background-color: #0033ff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1em;
  padding: 10px;
  width: 100%;
}

.submit-button:hover {
  background-color: #0024b4;
  transform: scale(1.1);
  transition: transform 0.3s ease, background-color 0.3s;
}

/* Contact styles */
.contactanos-container {
  left: 65%;
  position: absolute;
  text-align: center;
  top: 70%;
}

.contactanos-container h2 {
  display: flex;
  font-size: 2em;
  justify-content: center;
}

.contactanos-container p {
  display: flex;
  justify-content: center;
  size: 2em;
}

.contactanos-container ul {
  display: flex;
  left: 25%;
  list-style-type: none;
  position: absolute;
  top: 90%;
}

.contactanos-container ul li {
  margin: 0 10px;
}

/* Content styles */
.content {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  padding: 20px;
  text-align: left;
  width: 60%;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #fff;
}

.dark-mode .content {
  background-color: #1e1e1e;
  color: #ddd;
}

.dark-mode .enlace-producto {
  background-color: #555;
  color: #fff;
}

.dark-mode .enlace-producto:hover {
  background-color: #777;
}

.dark-mode .header {
  background-color: #333;
}

.dark-mode .producto {
  background-color: #1e1e1e;
  border: 1px solid #333;
  color: #ddd;
}

/* Footer styles */
.footer {
  background-color: #A4DDEA;
  padding: 20px 0;
  text-align: center;
  width: 100%;
}

.footer-content {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer.dark-mode {
  background-color: #333;
  color: #fff;
}

.footer.light-mode {
  background-color: #A4DDEA;
  color: #000;
}

.footer-logo {
  align-items: center;
  padding: 5px;
  width: 100px;
}

.footer-menu {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-menu-item {
  margin: 0 10px;
}

.footer-menu-item a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.footer-menu-item a:hover {
  text-decoration: underline;
}

.footer p {
  color: rgb(0, 0, 0);
  margin-top: 10px;
}

.footer-text {
  padding: 20px;
  padding-top: 30px;
}

/* Form styles */
.form-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 20px auto;
  max-width: 1000px;
  padding: 20px;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 95%;
}

.form-group label {
  margin-bottom: 5px;
}

/* Header styles */
.header {
  align-items: center;
  background-color: #FFBFA1;
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
}

.header-logo {
  flex: 0 0 auto;
}

.header-logo img {
  pointer-events: none;
  width: 150px;
}

/* Image background styles */
.imagen-fondo {
  background-image: url('../public/imagenes/tecnologia.jpg');
  background-size: cover;
  color: #ffffff;
  height: 700px;
  position: relative;
}

.imagen-fondo h1 {
  font-size: 3em;
  font-weight: 700;
  left: 3%;
  position: absolute;
  top: 3%;
}

/* Introduccion styles */
.introduccion {
  margin-bottom: 20px;
}

.introduccion p {
  color: #555;
  font-size: 1.2em;
}

/* Light mode styles */
.light-mode {
  background-color: #f5f5f5;
  color: #000;
}

/* Map styles */
.map-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Menu styles */
.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.menu-item {
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 2em;
  margin: 0 20px;
  padding: 10px 15px;
  transition: transform 0.3s ease, background-color 0.3s;
}

.menu-item a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.menu-item:hover {
  background-color: #ffa67d;
  border-color: #ff8248;
  transform: scale(1.1);
}

.menu-item:hover a {
  color: #000000;
  transform: scale(1.1);
}

/* Product styles */
.producto {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.producto h2 {
  color: #ff6f61;
  margin-bottom: 10px;
}

.producto p {
  color: #333;
}

.productos-container {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  padding: 20px;
  text-align: justify;
  width: 60%;
}

.enlace-producto {
  background-color: #ff6f61;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.enlace-producto:hover {
  background-color: #e65550;
}

/* Section styles */
.section-content {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
}

/* Selector buttons styles */
.selector-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.selector-buttons button {
  background-color: #A4DDEA;
  border: none;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 1em;
  margin: 0 10px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.selector-buttons button:hover {
  background-color: #0dd3ff;
}

/* Switch styles */
.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  background-color: white;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Text container styles */
.text-container {
  left: 58%;
  position: absolute;
  text-align: center;
  top: 26%;
}

.text-container h2, .text-container h3 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
